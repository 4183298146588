/*
 *
 * Roles actions
 *
 */

import {
  TOGGLE_SHOW_CREATE_ROLE_FORM,
  GET_ROLES,
  GET_ROLES_SUCCESS,
  GET_ROLES_ERROR,
  CREATE_ROLE,
  CREATE_ROLE_SUCCESS,
  CREATE_ROLE_ERROR,
  COPY_ROLE,
  COPY_ROLE_SUCCESS,
  COPY_ROLE_ERROR,
  EDIT_ROLE,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_ERROR,
  DELETE_ROLE,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_ERROR,
  GET_ROLES_UNDER_CUSTOMER,
  GET_ROLES_UNDER_CUSTOMER_SUCCESS,
} from './constants'

export const toggleShowCreateRoleForm = () => ({
  type: TOGGLE_SHOW_CREATE_ROLE_FORM,
})

export const getRoles = (customerCode, companyCode) => ({
  type: GET_ROLES,
  customerCode,
  companyCode,
})

export const getRolesUnderCustomer = (
  customerCode,
  includeSystemRoles = false
) => ({
  type: GET_ROLES_UNDER_CUSTOMER,
  customerCode,
  includeSystemRoles,
})

export const getRolesUnderCustomerSuccess = (roles) => ({
  type: GET_ROLES_UNDER_CUSTOMER_SUCCESS,
  roles,
})

export const getRolesSuccess = (roles, companyCode, customerCode) => ({
  type: GET_ROLES_SUCCESS,
  roles,
  companyCode,
  customerCode,
})

export const getRolesError = ({ error, companyCode }) => ({
  type: GET_ROLES_ERROR,
  companyCode,
  error,
})

export const createRole = ({
  customerCode,
  companyCode,
  roleName,
  autoAssign,
  userId,
}) => ({
  type: CREATE_ROLE,
  customerCode,
  companyCode,
  roleName,
  autoAssign,
  userId,
})

export const createRoleSuccess = ({ id, customerCode, companyCode, name }) => ({
  type: CREATE_ROLE_SUCCESS,
  customerCode,
  companyCode,
  name,
  id,
})

export const createRoleError = (error) => ({
  type: CREATE_ROLE_ERROR,
  error,
})

export const copyRole = ({
  customerCode,
  companyCode,
  roleId,
  copyRoleName,
}) => ({
  type: COPY_ROLE,
  customerCode,
  companyCode,
  roleId,
  copyRoleName,
})

export const copyRoleSuccess = ({ id, customerCode, companyCode, name }) => ({
  type: COPY_ROLE_SUCCESS,
  customerCode,
  companyCode,
  name,
  id,
})

export const copyRoleError = (error) => ({
  type: COPY_ROLE_ERROR,
  error,
})

export const editRole = ({
  customerCode,
  companyCode,
  roleId,
  editedRoleName,
}) => ({
  type: EDIT_ROLE,
  customerCode,
  companyCode,
  roleId,
  editedRoleName,
})

export const editRoleSuccess = ({ id, customerCode, companyCode, name }) => ({
  type: EDIT_ROLE_SUCCESS,
  customerCode,
  companyCode,
  name,
  id,
})

export const editRoleError = (error) => ({
  type: EDIT_ROLE_ERROR,
  error,
})

export const deleteRole = ({ customerCode, companyCode, roleId }) => ({
  type: DELETE_ROLE,
  customerCode,
  companyCode,
  roleId,
})

export const deleteRoleSuccess = ({ customerCode, companyCode, roleId }) => ({
  type: DELETE_ROLE_SUCCESS,
  customerCode,
  companyCode,
  roleId,
})

export const deleteRoleError = (error) => ({
  type: DELETE_ROLE_ERROR,
  error,
})
