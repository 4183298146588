import { createSelector } from 'reselect'
import { customerFilter } from 'containers/Customer/selectors'
import { companyFilter } from 'containers/Company/selectors'
import selectCustomers from 'containers/Customers/selectors'
import { alphabeticallyAscendingComparer } from 'utils/sort'

/**
 * Direct selector to the users state domain
 */
const selectUsersDomain = (state) => state.get('users')

/**
 * Other specific selectors
 */

const selectCompanies = createSelector(
  [selectCustomers, customerFilter, companyFilter],
  (customers, customerCode, companyCode) => {
    if (customerCode && companyCode) {
      return customers
        .flatMap((c) => c.companies)
        .filter((c) => c.code === companyCode)
    } else if (customerCode) {
      const customer = customers
        .filter((c) => c.code === customerCode.toString())
        .get(0)
      return customer && customer.companies
    }

    return customers.flatMap((c) => c.companies)
  }
)

const selectUsers = createSelector(
  selectUsersDomain,
  (users) => users.get('users').sort(alphabeticallyAscendingComparer)
)

const selectShowCreateUserForm = () =>
  createSelector(
    selectUsersDomain,
    (users) => users.get('showCreateUserForm')
  )

const selectLoading = createSelector(
  selectUsersDomain,
  (users) => users.get('loading')
)

const selectError = createSelector(
  selectUsersDomain,
  (users) => users.get('error')
)

const selectFilteredUsers = () =>
  createSelector(
    selectUsersDomain,
    (users) => users.get('filteredUsers')
  )

const selectShowDefaultRolesModal = createSelector(
  selectUsersDomain,
  (users) => users.get('showAddDefaultRolesModal')
)

const selectCreatedUser = createSelector(
  selectUsersDomain,
  (users) => users.get('createdUser')
)

const selectSelectedUser = createSelector(
  selectUsersDomain,
  (users) => users.get('selectedUser')
)

const selectSelectedUserLoading = createSelector(
  selectUsersDomain,
  (users) => users.get('selectedUserLoading')
)
const selectUsersByCompany = createSelector(
  [selectUsersDomain],
  (users, companyCodes) => users.get('usersByCompany')
)

const selectUsersCompaniesLoading = createSelector(
  selectUsersDomain,
  (users) => users.get('companyLoading')
)

const selectUsersByCompaniesError = createSelector(
  selectUsersDomain,
  (users) => users.get('companyErrors')?.toJS()
)

export default selectUsers
export {
  selectCompanies,
  selectCreatedUser,
  selectShowCreateUserForm,
  selectFilteredUsers,
  selectShowDefaultRolesModal,
  selectUsersDomain,
  selectLoading,
  selectError,
  selectSelectedUser,
  selectSelectedUserLoading,
  selectUsersByCompany,
  selectUsersCompaniesLoading,
  selectUsersByCompaniesError,
}
